import React, { useState } from "react";
import { Link } from "react-scroll";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaGithub } from "react-icons/fa";
import { logo } from "../../assets/index"; // Ensure you have the correct path
import { navLinksdata } from "../../constants"; // Ensure you have the correct path

const Sidebar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredLink(id);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  return (
    <div>
      <span
        onClick={() => setShowMenu(!showMenu)}
        style={{
          position: "fixed",
          top: "20px",
          left: showMenu ? "270px" : "20px", // Adjust the left position based on showMenu state
          fontSize: "24px",
          background: "black",
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          color: "white",
          cursor: "pointer",
          transition: "left 0.3s ease", // Smooth transition for the movement
          zIndex: 1000,
        }}
      >
        {showMenu ? <MdClose /> : <FiMenu />}
      </span>
      {showMenu && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "250px",
            height: "100%",
            backgroundColor: "#1a1a1a",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            color: "white",
            zIndex: 999,
            transition: "transform 0.3s ease", // Smooth transition for the sidebar
          }}
        >
          <div>
            <img
              src={logo}
              alt="logo"
              style={{ width: "100px", marginBottom: "20px" }}
            />
            <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
              {navLinksdata.map(({ _id, title, link }) => (
                <li
                  key={_id}
                  style={{
                    marginBottom: "10px",
                    color: hoveredLink === _id ? "red" : "#ecf0f1",
                    cursor: "pointer",
                    transition: "color 0.3s ease",
                    fontSize: "18px",
                    fontFamily: "Arial, sans-serif",
                  }}
                  onMouseEnter={() => handleMouseEnter(_id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    onClick={() => setShowMenu(false)}
                    to={link}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      display: "block",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#2c3e50")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "inherit")
                    }
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
            <p
              style={{
                marginTop: "40px",
                fontSize: "16px",
                lineHeight: "1.6",
                color: "#f0f0f0",
                backgroundColor: "#333",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                fontStyle: "italic",
                textAlign: "center",
                borderLeft: "5px solid #ff9800",
              }}
            >
              In the world of software engineering, every line of code is a step
              towards innovation and progress. Embrace the challenges, for they
              are opportunities in disguise. Each bug you fix, each feature you
              build, is a testament to your skill and determination... 🌟
            </p>
          </div>
          <div>
            <h2
              style={{
                fontSize: "16px",
                textTransform: "uppercase",
                marginBottom: "10px",
              }}
            >
              Find me in
            </h2>
            <div style={{ display: "flex", gap: "20px" }}>
              <a
                href="https://www.facebook.com/profile.php?id=100077739931803"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                <FaFacebookF
                  style={{ cursor: "pointer", transition: "color 0.3s" }}
                />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                <FaTwitter
                  style={{ cursor: "pointer", transition: "color 0.3s" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/motekema-mahlanya-58ab22230/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                <FaLinkedinIn
                  style={{ cursor: "pointer", transition: "color 0.3s" }}
                />
              </a>
              <a
                href="https://github.com/Motekema"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                <FaGithub
                  style={{ cursor: "pointer", transition: "color 0.3s" }}
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
