import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            Achievements
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Company Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Deployed Loan Management Backend"
            subTitle="Vercel"
            result="Success"
            des="Successfully deployed a Loan Management backend project on Vercel, which is live and accessible."
          />
          <ResumeCard
            title="Airbnb Project"
            subTitle="ALX Africa"
            result="Success"
            des="Contributed to the Airbnb project during my studies at ALX Africa, gaining practical experience in full-stack development."
          />
          <ResumeCard
            title="Deployed Backend Project"
            subTitle="Vercel"
            result="Success"
            des="Successfully deployed another backend project on Vercel, demonstrating consistency and reliability in deployment."
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            Job Experience
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Full Stack Developer"
            subTitle="Alx Africa - (2023 - Present)"
            result="Westlands, Nairobi, Kenya"
            des="Developed and maintained web applications, contributed to the Airbnb project, and gained hands-on experience in full stack development."
          />
          <ResumeCard
            title="Web Developer & Trainer"
            subTitle="ALX Team - (2023 - Present)"
            result="Westlands, Nairobi, Kenya"
            des="A popular destination with a growing number of highly qualified homegrown graduates, it's true that securing a role in Westlands, Nairobi, Kenya isn't easy."
          />
          <ResumeCard
            title="Front-end Developer"
            subTitle="Zeemoney - (2021 - 2022)"
            result="Burgersfort, South Africa"
            des="The Burgersfort, South Africa economy has grown strongly over recent years, having transformed itself from a producer and innovation-based economy."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Achievement;
