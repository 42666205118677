import React from "react";
import Title from "../layouts/Title";
import { projectOne, projectTwo, projectThree } from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Loan Management"
          des=" Loan management is a crucial process in the financial industry that 
          involves the systematic handling of loans throughout their lifecycle. 
          This process encompasses a range of activities from loan origination, 
          processing, and approval, to disbursement, servicing, and repayment. 
          Effective loan management ensures that lenders can efficiently track 
          and manage their loan portfolios, mitigate risks, and provide a seamless 
          experience for borrowers."
          src={projectOne}
        />
        <ProjectsCard
          title="Personal Financial Manager"
          des=" A Personal Financial Manager (PFM) is a comprehensive tool designed to help 
          individuals take control of their financial lives. It provides a centralized platform
           for managing personal finances, enabling users to track their income, expenses, savings,
            and investments. The goal of a PFM is to empower individuals to make informed financial decisions, 
            achieve their financial goals, and secure their financial future."
          src={projectTwo}
        />
        <ProjectsCard
          title="AirBnB"
          des="Airbnb is a popular online marketplace that connects people looking for accommodations with those who have properties available for short-term rental. Founded in 2008, Airbnb has revolutionized the travel and hospitality industry by offering a diverse range of lodging options, from private homes and apartments to unique stays like treehouses and castles."
          src={projectThree}
        />
      </div>
    </section>
  );
};

export default Projects;
