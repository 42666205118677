import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2011 - 2024</p>
          <h2 className="text-3xl md:text-4xl font-bold">
            Education Quality in IT
          </h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Full Stack Developer | Backend"
            subTitle="AlX SE (2023 - 2024)"
            result="3.90/4"
            des="The training provided by Alx in order to prepare people to work in various sectors of the economy or areas of culture."
          />
          <ResumeCard
            title="Information Technology and Computer Science"
            subTitle="Tshwane North Tvet College (2018 - 2022)"
            result="4.75/5"
            des="Higher education is college education leading to award of an academic Level 4. Higher education, also called post-secondary education."
          />
          <ResumeCard
            title="Secondary School Education"
            subTitle="Ramphelane Secondary School (2011 - 2017)"
            result="5.00/5"
            des="Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
          />
        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2021 - 2023</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Junior Software Engineer"
            subTitle="Alx Africa - (2023 - Present)"
            result="Westlands, Nairobi, Kenya"
            des="At ALX, the Software Engineering program has been an essential part of my growth and development as a developer. Through the rigorous curriculum and hands-on projects, I've gained a deep understanding of both foundational and advanced concepts in software engineering."
          />
          <ResumeCard
            title="Web Developer"
            subTitle="Zeemoney - (2021 - 2022)"
            result="Burgersfort, South Africa"
            des="As part of my role, I developed a comprehensive loan management system for Zeemoney, designed to streamline and enhance their loan processing operations. This project involved creating a robust back-end infrastructure that ensures efficient management of loan data, customer information, and financial transactions."
          />
          <ResumeCard
            title="Front-end Developer"
            subTitle="LocalITservices - (2020 - 2024)"
            result="This is the company is started by me"
            des="Local IT Services is a business focused on providing a range of technology solutions to individuals and organizations."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
