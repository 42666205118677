import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2018 - 2024</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Full Stack Developer"
            subTitle="ALX Africa - (2023 - Present)"
            result="Westlands, Nairobi, Kenya"
            des="Developed and maintained web applications, contributed to the Airbnb project, and gained hands-on experience in full stack development."
          />
          <ResumeCard
            title="Web Developer & Trainer"
            subTitle="Local IT Services"
            result="Kenya"
            des="Provided web development services and trained upcoming developers, sharing knowledge and expertise in the field."
          />
          <ResumeCard
            title="Front-end Developer"
            subTitle="Legendary Foot ware - (2018 - 2020)"
            result="Pertoria"
            des="The Pertoria economy has grown strongly over recent years, having transformed itself from a producer and innovation-based economy."
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2001 - 2020</p>
          <h2 className="text-3xl md:text-4xl font-bold">Trainer Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Gym Instructor"
            subTitle="Rainbow Gym Center (2015 - 2020)"
            result="Jane Furse"
            des="The training provided Rainbow Gym Center in order to prepare people to work in various sectors of the economy or areas of culture."
          />
          <ResumeCard
            title="Web Developer and Instructor"
            subTitle="Tshwane North Tvet College (2018 - 2022)"
            result="Tshwane"
            des="Higher education is tertiary education leading to the award of an academic degree. Higher education, also called post-secondary education."
          />
          <ResumeCard
            title="Internet Cafe"
            subTitle="Mamelodi (2021 - 2020)"
            result="Mamelodi X24"
            des="The training provided by College in order to prepare people to work in various sectors of the economy or areas of culture."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
